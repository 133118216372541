<template>
  <div class="h-full min-w-0 pr-2 overflow-x-auto border-r w-72">
    <AssetCollection
      :project="project"
      :assets="assets"
      :active-folder="activeFolder"
      :multiselected-assets="multiselectedAssets"
      :focused-asset="focusedAsset"
      :can-preview="canPreview"
      :can-select="canSelect"
      :can-replace="canReplace"
      :can-delete="canDelete"
      :can-rename="canRename"
      :dragger="dragger"
      :layout="'tree'"
      :selected-asset="selectedAsset"
      :sort-order="null"
      :display-mode="displayMode"
      :restricted-action-types="restrictedActionTypes"
      :action-mode="actionMode"
      @multiselect-asset="$emit('multiselect-asset', $event)"
      @multideselect-asset="$emit('multideselect-asset', $event)"
      @delete-asset="$emit('delete-asset', $event)"
      @preview-asset="$emit('preview-asset', $event)"
      @rename-asset="$emit('rename-asset', $event)"
      @replace-asset="$emit('replace-asset', $event)"
      @deselect-asset="$emit('deselect-asset', $event)"
      @drag-asset="$emit('drag-asset', $event)"
      @drag-asset-focus="$emit('drag-asset-focus', $event)"
      @drop-asset="$emit('drop-asset', $event)"
      @select-asset="$emit('select-asset', $event)"
      @view-asset="$emit('view-asset', $event)"
      @move-asset="$emit('move-asset', $event)"
      @sort-order-change="sortOrder = $event"
    />

    <ul
      v-if="false"
      role="list"
      class="leading-6 divide-y divide-gray-200 select-none"
    >
      <!-- Folder Item -->
      <li class>
        <div class="flex items-center p-0.5 pl-1 rounded cursor-pointer hover:bg-gray-200">
          <button
            type="button"
            class="inline-flex items-center p-1 text-gray-400 border border-transparent rounded hover:bg-gray-300 focus:outline-none"
          >
            <fa-icon
              icon="chevron-down"
              class="fa-fw"
              size="xs"
            />
          </button>
          <fa-icon
            icon="folder"
            class="mr-2 text-blue-400 fa-fw"
          />
          <div class="text-sm leading-6 text-gray-500">
            Folder name
          </div>
        </div>
        <ul>
          <li>
            <div
              class="pl-4 flex items-center p-0.5 rounded cursor-pointer hover:bg-gray-200"
            >
              <button
                type="button"
                class="inline-flex items-center p-1 text-gray-400 border border-transparent rounded hover:bg-gray-300 focus:outline-none"
              >
                <fa-icon
                  icon="chevron-right"
                  class="fa-fw"
                  size="xs"
                />
              </button>
              <fa-icon
                icon="folder"
                class="mr-2 text-blue-400 fa-fw"
              />

              <div class="text-sm leading-6 text-gray-500">
                Folder name
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li class>
        <div class="flex items-center p-0.5 pl-1 rounded cursor-pointer hover:bg-gray-200">
          <button
            type="button"
            class="inline-flex items-center p-1 text-gray-400 border border-transparent rounded hover:bg-gray-300 focus:outline-none"
          >
            <fa-icon
              icon="chevron-right"
              class="fa-fw"
              size="xs"
            />
          </button>
          <fa-icon
            icon="folder"
            class="mr-2 text-blue-400 fa-fw"
          />
          <div class="text-sm leading-6 text-gray-500">
            Folder name
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
const AssetCollection = () => import('./assets-explorer-collection.vue')
export default {

  components: {
    'AssetCollection': AssetCollection
  },

  props: {

    assets: {
      required: true,
      type: Array
    },
    activeFolder: {
      required: true,
      type: Object
    },
    canPreview: {
      required: true,
      type: Boolean
    },
    canSelect: {
      required: true,
      type: Boolean
    },
    canReplace: {
      required: true,
      type: Boolean
    },
    displayMode: {
      required: true,
      type: String
    },
    canDelete: {
      required: true,
      type: Boolean
    },
    canRename: {
      required: true,
      type: Boolean
    },
    dragger: {
      default: null,
      type: Object
    },
    project: {
      required: true,
      type: Object
    },
    selectedAsset: {
      default: null,
      type: Object
    },
    focusedAsset: {
      default: null,
      type: Object
    },
    multiselectedAssets: {
      required: true,
      type: Array
    },
    restrictedActionTypes: {
      type: Array,
      default: () => []
    },
    actionMode: {
      type: String,
      required: true
    }
  },

  computed: {

    // rootFolder() {

    //     return this.$store.getters['assets/rootFolder']
    // },

    // assets() {
    //     return this.$store.getters['assets/loadedSets']
    // },
    /**
           * The assets to display, taking into account we only want folders,
           */
    folderAssets() {
      let assets = this.assets.filter(function (asset) {
        return asset.type === 'folder'
      })

      return assets
    }
  }

}
</script>
